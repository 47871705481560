import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/home';
// import EventDetailPage from './pages/eventDetail';
import SignUpPage from './pages/signup';
// import LoginPage from './pages/login';
import ProtectedRoute from './components/PrivateRoute/ProtectedRoute'
import { isAuthenticated } from './utils/auth';
import { AuthProvider } from './context/AuthContext';
import NotFoundScreen from './pages/notFound';
import ProfilePage from './pages/profile';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import CreateEventPage from './pages/createEvent';

function App() {

  const [setAuth] = useState(false);

  useEffect(() => {
    if(isAuthenticated()) {
      setAuth(true);
    }
  });

  return (
    <div className='App'>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path='/' element={<HomePage/>} />
            <Route path='/register' element={<SignUpPage/>}/>
            {/* <Route path='/login' element={<LoginPage/>}/> */}
            {/* <Route path='/event/:id' element={<EventDetailPage/>}/> */}
            <Route path='*' element={<NotFoundScreen />} />
            {/* <Route path='/profile' element={<ProfilePage/>} /> */}
            {/* <Route path='/create-event' element={<CreateEventPage/>}/> */}
            <Route 
              path='/profile' 
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              } 
            />
          </Routes>
        </Router>
        <ToastContainer/>
      </AuthProvider>
    </div>
  );
}

export default App;
