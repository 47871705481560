// components/EventsSection.js
import React, {useEffect, useState} from 'react';
import EventCard from './EventCard';
import './EventsSection.css';
import {getEvents} from '../../constants/routes';

function EventsSection() {

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(getEvents);
        if(!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        const data = await response.json();
        setEvents(data.events);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div className="error-message">
        <p>Error: {error.message}</p>
        <button onClick={() => window.location.reload()}>Try Again</button>
      </div>
    );
  }
  

  return (
    <section className="upcoming-events-section">
      <div className='events-header'>
      <h2>Trending Events</h2>
      {/* <div className="filter-buttons">
        <button>Weekdays <i className="fas fa-caret-down"></i></button>
        <button>Event Type <i className="fas fa-caret-down"></i></button>
        <button>Any Category <i className="fas fa-caret-down"></i></button>
      </div> */}
      </div>
      <div className="events-grid">
        {events.map((event, index) => (
          <EventCard key={index} event={event} />
        ))}
      </div>
      {/* <button className="load-more">Load More</button> */}
    </section>
  );
}

export default EventsSection;
