import React from 'react';
import './Footer.css';
import appleLogo from '../../assets/apple-icon.png';
import googleLogo from '../../assets/google-icon.png';
// import startUpBadge from '../../assets/startup_badge_dark.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section company-info">
          <h4>Company Info</h4>
          <ul>
            <li>About Us</li>
            <li>Contact Us</li>
            {/* <li>FAQs</li> */}
            <li>Terms of Service</li>
            <li>Privacy Policy</li>
          </ul>
        </div>
        <div className="footer-section help">
          <h4>Help</h4>
          <ul>
            <li>Account Support</li>
            <li>Listing Events</li>
            <li>Event Ticketing</li>
            {/* <li>Ticket Purchase Terms & Conditions</li> */}
          </ul>
        </div>
        {/* <div className="footer-section categories">
          <h4>Categories</h4>
          <ul>
            <li>Concerts & Gigs</li>
            <li>Festivals & Lifestyle</li>
            <li>Business & Networking</li>
            <li>Food & Drinks</li>
            <li>Performing Arts</li>
            <li>Sports & Outdoors</li>
            <li>Exhibitions</li>
            <li>Workshops, Conferences & Classes</li>
          </ul>
        </div> */}
        <div className="footer-section follow-us">
          <h4>Follow Us</h4>
          <ul>
            <li>Facebook</li>
            <li>Instagram</li>
            <li>Twitter</li>
            {/* <li>Youtube</li> */}
          </ul>
        </div>
        <div>
          <h4>Download The App</h4>
          <div className="app-logos">
            <img src={googleLogo} alt="Google Play" />
            <img src={appleLogo} alt="App Store" />
          </div>
        </div>
        {/* <div className="startup">
          <img src={startUpBadge} alt="startup-badge" />
        </div> */}
      </div>
      <div className="footer-bottom">
        ©2024 Entertico. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
